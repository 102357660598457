import React from "react";
import { CurrentWeather } from "../interfaces/current-weather";
// import Daily from "./Daily";
import Chart from "./Chart";

interface MainComponent {
  targetLocation: string;
  addExtraSpaceOnTop: boolean;
  current: CurrentWeather;
  forecast: any;
}

interface State {
  timerId?: number;
  dateTime: string;
}

class Main extends React.Component<MainComponent> {
  options: any = {
    weekday: "long",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };

  state: State = {
    dateTime: new Date().toLocaleTimeString("en-GB", this.options),
  };

  tick() {
    this.setState({
      dateTime: new Date().toLocaleTimeString("en-GB", this.options),
    });
  }

  componentDidMount() {
    this.setState({
      timerId: setInterval(() => {
        this.tick();
      }, 1000),
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.timerId);
  }

  render() {
    const location = this.props.targetLocation;
    const extraSpaceOnTop = this.props.addExtraSpaceOnTop;
    const trimmedLocation = location.split(",")[0];

    const { dateTime } = this.state;

    const current: CurrentWeather = this.props.current;
    const forecast: any = this.props.forecast;

    return (
      <div className="container-fluid main">
        <div className={`row first-row ${extraSpaceOnTop ? "first-row-extra-space-on-top" : ""}`}>
          <div className="col align-self-start">
            <span className="location">{trimmedLocation}</span>
            <br></br>
            <span className="date-time">{dateTime}</span>
          </div>

          {/* <div className="col">
                <span className="human-perception">
                  Feels like {Math.round(current.main.feels_like)}°
                </span>
              </div> */}
          <div className="col align-self-end">
            <div className="d-flex float-end">
              <div className="human-perception align-self-center">
                Feels like {Math.round(current.main.feels_like)}°
              </div>
              <div className="temperature">
                {Math.round(current.main.temp)}°C
              </div>
            </div>
          </div>
        </div>

        <div className={`row second-row ${extraSpaceOnTop ? "second-row-extra-space-on-top" : ""}`}>
          <div className="col">
            <div className="row">
              <div className="col-3 text-center portrait">
                <i className={"icon wi wi-owm-" + current.weather[0].id}></i>
                <br></br>
                <span className="icon-description">
                  {current.weather[0].description}
                </span>
              </div>

              <div className="col-8 offset-1 portrait">
                <div className="chart">
                  <Chart data={forecast.list}></Chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
