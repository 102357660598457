import React, { FunctionComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  ResponsiveContainer,
  // CartesianGrid,
} from "recharts";
// import { curveCardinal  } from 'd3-shape';

interface ChartComponent {
  data: any;
}

class Chart extends React.Component<ChartComponent> {
  render() {
    const data: any = this.props.data;

    if (data) {
      for (let index = 0; index < data.length; index += 1) {
          const element = data[index];

          const hours = new Date(element.dt_txt).getHours().toString().padStart(2, '0');
          const minutes = new Date(element.dt_txt).getMinutes().toString().padStart(2, '0');

          data[index].hours = `${hours}:${minutes}`;
      }
    }

    const CustomizedDot: FunctionComponent<any> = (props: any) => {
      const { cx, cy, payload, value } = props;

      return (
        <svg>
          <text x={cx - 10} y={cy - 15} width={20} height={20} fill="#fff" className="chart-svg-text">
            {Math.round(value)}°
          </text>
          {/* @TODO - Change colour based on temp? - https://thinkmetric.uk/basics/temperature/ */}
          {/* <circle cx={cx} cy={cy} r="4" fill="#fff" /> */}
          <foreignObject x={cx - 10} y={cy + 15} width="40" height="40">
            <i className={"chart-svg-icon icon wi wi-owm-" + payload.weather[0].id}></i>
          </foreignObject>
        </svg>
      );
    };


    return (
      <ResponsiveContainer width="100%">
        <LineChart
          data={data}
          margin={{
            top: 40,
            right: 40,
            left: 40,
            bottom: 0,
          }}
        >
          <Line
            type="monotone"
            dataKey="main.temp"
            stroke="#fff"
            dot={<CustomizedDot />}
          />
          {/* <CartesianGrid strokeDasharray="3 3" horizontal={false} /> */}
          <XAxis dataKey="hours" stroke="#fff" />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

export default Chart;
