import React from "react";
import { CurrentWeather } from "./interfaces/current-weather";
import Main from "./components/Main";

interface State {
  timerId?: number;
  error: boolean;
  isLoaded: boolean;
  // location: string;
  currentWeatherData: CurrentWeather;
  forecastWeatherData: any;
}

class App extends React.Component {
  // currentWeatherData: Weather = {
  //   weather: [{
  //     id: 0,
  //     main: '',
  //     description: '',
  //     icon: ''
  //   }],
  //   main: {
  //     temp: 0,
  //     feels_like: 0,
  //     temp_min: 0,
  //     temp_max: 0,
  //     pressure: 0,
  //     humidity: 0,
  //   }
  // };
  state: State = {
    error: false,
    isLoaded: false,
    // location: 'London,UK',
    currentWeatherData: {
      weather: [
        {
          id: 0,
          main: "",
          description: "",
          icon: "",
        },
      ],
      main: {
        temp: 0,
        feels_like: 0,
        temp_min: 0,
        temp_max: 0,
        pressure: 0,
        humidity: 0,
      },
    },
    forecastWeatherData: {},
  };

  windowUrl = window.location.search;
  params = new URLSearchParams(this.windowUrl);

  // Minutes
  dataFetchInterval: number = 15;

  locationParam: string | null = this.params.get("location");
  location: string = "London,uk";

  extraTopSpacingParam: string | null = this.params.get("extra-top-spacing");
  extraTopSpacing: boolean = false;

  validateParams() {
    if (this.locationParam && this.locationParam.trim() !== "") {
      // "this.setState({})" will be too late for "getData()".
      this.location = this.locationParam;
    }

    if (
      this.extraTopSpacingParam &&
      this.extraTopSpacingParam.trim() !== "" &&
      (this.extraTopSpacingParam === "true" ||
        this.extraTopSpacingParam === "false")
    ) {
      this.extraTopSpacing = this.extraTopSpacingParam === "true";
    }
  }

  getData() {
    const openWeatherApiKey = "dd86c615d41974907e09301284add0a1";
    const openWeatherApiUrl = `//api.openweathermap.org/data/2.5/`;
    const currentApiEndpoint = `${openWeatherApiUrl}weather?q=${this.location}&units=metric&appid=${openWeatherApiKey}`;
    const forecastApiEndpoint = `${openWeatherApiUrl}forecast?q=${this.location}&units=metric&cnt=10&appid=${openWeatherApiKey}`;

    // Get current weather data
    fetch(currentApiEndpoint)
      .then((res) => res.json())
      .then(
        (result: CurrentWeather) => {
          this.setState({
            // isLoaded: true,
            currentWeatherData: result,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            // isLoaded: true,
            error: true,
          });
        }
      );

    // Get weather forecast
    fetch(forecastApiEndpoint)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            forecastWeatherData: result,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error: true,
          });
        }
      );
  }

  componentDidMount() {
    this.validateParams();

    // Initial call, then interval will take charge
    this.getData();

    this.setState({
      timerId: setInterval(() => {
        this.getData();
      }, 6e4 * this.dataFetchInterval),
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.timerId);
  }

  render() {
    const { error, isLoaded, currentWeatherData, forecastWeatherData } =
      this.state;

    if (error) {
      return <div>Error</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <Main
          targetLocation={this.location}
          addExtraSpaceOnTop={this.extraTopSpacing}
          current={currentWeatherData}
          forecast={forecastWeatherData}
        ></Main>
      );
    }
  }
}

export default App;
